import "../../../../../components/pop-up/popup.scss";
import "gridstack/dist/gridstack.css";
import "gridstack/dist/es5/gridstack-poly.js";
import "gridstack/dist/es5/gridstack-all.js";
import "../../styles.scss";

import { GridStack } from "gridstack/dist/es5/gridstack";
import React, { useEffect, useState } from "react";
import ScrollView from "devextreme-react/scroll-view";
import Popup from "devextreme-react/popup";
import { useController, useFieldArray, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { Form } from "../../form";
import Button from "../button/button";
import styles from "./style.module.scss";
import {
  CheckmarkIcon,
  DotSixVerticalIcon,
  TrashIcon,
} from "../../../../../assets";

function AppendItem({ append }) {
  const [value, setValue] = useState("");

  return (
    <div className={styles.itemContainer}>
      <div className={styles.dotsContainer}>
        <DotSixVerticalIcon />
      </div>
      <div className={styles.inputContainer}>
        <input
          className={styles.textInput}
          onChange={(event) => setValue(event.currentTarget.value)}
          value={value}
          placeholder="New Value"
        />
      </div>
      <Button
        className={`${styles.iconButton} ${styles.checkmarkButton}`}
        onClick={() => {
          if (!!value) {
            append({ value });
            setValue("");
          }
        }}
      >
        <CheckmarkIcon />
      </Button>
    </div>
  );
}

function EditItem({ name, remove }) {
  const { field } = useController({ name });
  return (
    <div className={`grid-stack-item-content ${styles.itemContainer}`}>
      <div className={styles.dotsContainer}>
        <DotSixVerticalIcon />
      </div>
      <div className={styles.inputContainer}>
        <input className={styles.textInput} {...field} />
      </div>
      <Button
        className={`${styles.iconButton} ${styles.trashButton}`}
        onClick={remove}
      >
        <TrashIcon />
      </Button>
    </div>
  );
}

function Items({ control }) {
  const { append, fields, remove } = useFieldArray({ name: "items", control });
  const gridRef = React.useRef();
  const itemsRef = React.useRef({});

  if (Object.keys(itemsRef.current).length !== fields.length) {
    fields.forEach(({ id }) => {
      itemsRef.current[id] = itemsRef.current[id] || React.createRef();
    });
  }

  useEffect(() => {
    gridRef.current =
      gridRef.current ||
      GridStack.init(
        {
          cellHeight: 50,
          minRow: 1,
          column: 1,
          acceptWidgets: true,
          disableResize: true,
        },
        ".option-grid"
      );
    // const grid = gridRef.current;
    // if (grid) {
    // }
    // grid?.on("added removed change", function (e, items) {
    //   let str = "";
    //   //   items.forEach(function (item) {
    //   //     str += " (x,y)=" + item.x + "," + item.y;
    //   //   });
    //   console.log(e.type + " " + items.length + " items:" + str);
    // });
    // grid.batchUpdate();
    // grid.removeAll(false);
    // fields.forEach(({ id }) => grid.makeWidget(itemsRef.current[id].current));
    // grid.batchUpdate(false);
  }, [fields]);

  React.useEffect(() => {
    const grid = gridRef.current;
    if (grid) {
      grid.batchUpdate();
      grid.removeAll(false);
      fields.forEach(
        ({ id }) =>
          itemsRef.current[id] && grid.makeWidget(itemsRef.current[id].current)
      );
      grid.batchUpdate(false);
    }
  }, [fields]);

  // console.log(fields);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="grid-stack option-grid" style={{ width: "100%" }}>
          {fields.map((curr, idx) => (
            <div
              ref={itemsRef.current[curr.id]}
              key={curr.id}
              className="grid-stack-item"
            >
              <div className="grid-stack-item-content">
                <EditItem
                  // key={curr.id}
                  name={`items.${idx}.value`}
                  remove={() => remove(idx)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <AppendItem append={append} grid={gridRef.current} />
    </>
  );
}

export default function SelectionPopup(props) {
  const { onHiding, visible, items = [], onItemsChange } = props;
  // const [options, setOptions] = useState(items);

  const methods = useForm({
    defaultValues: {
      items: [],
    },
    mode: "onChange",
  });
  const { setValue, control } = methods;

  useEffect(() => {
    if (visible) {
      setValue(
        "items",
        items.map((curr) => ({
          id: uuid(),
          value: curr,
        }))
      );
    }
  }, [setValue, visible]);

  const onSubmit = (values) => {
    onItemsChange?.(values.items.map((curr) => curr.value));
    onHiding(false);
  };

  // return (
  //   <DefaultDialog>
  //     <ScrollView width="100%" height="90%">
  //       <Form methods={methods} onSubmit={onSubmit}>
  //         <b style={{ fontSize: 14 }}>Specify all possible values</b>
  //         <Items control={control} />
  //         <div style={{ display: "flex", gap: 8, margin: "24px 0" }}>
  //           <Button type="submit">Confirm</Button>
  //           <Button
  //             type="button"
  //             variant="secondaryBlue"
  //             onClick={() => {
  //               onHiding(false);
  //             }}
  //           >
  //             Cancel
  //           </Button>
  //         </div>
  //       </Form>
  //     </ScrollView>
  //   </DefaultDialog>
  // );

  return (
    <Popup
      className="item-popup"
      title="Selections Options"
      onHiding={onHiding}
      // visible={visible}
      visible
      dragEnabled
      showTitle
      width="400"
      height={500}
      closeOnOutsideClick
    >
      <Form methods={methods} onSubmit={onSubmit} style={{ height: "100%" }}>
        <ScrollView width="100%" height="90%">
          <b style={{ fontSize: 14 }}>Specify all possible values</b>
          <Items control={control} />
        </ScrollView>
        <div style={{ display: "flex", gap: 8, margin: "24px 0 8px" }}>
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={() => {
              onHiding(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Popup>
  );
}
