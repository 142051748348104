import React from "react";
import TextBox from "devextreme-react/text-box";
import PropTypes from "prop-types";
import "./text-box.scss";
import "./text-box-additional.scss";

export default function DefaultSearchBox(props) {
  const {
    onTbvalueChange,
    value,
    disabled,
    caption,
    stylingMode = "outlined",
    placeholder,
    direction = "vertical",
    labelWidth,
    justify: _justify = "left",
    align: _align = "left",
    className,
    labelClassName,
  } = props;

  const justify = React.useMemo(() => `justify ${_justify}`, [_justify]);
  const align = React.useMemo(() => `align ${_align}`, [_align]);

  function tb_onChangedHandler(event) {
    onTbvalueChange?.(event.value);
  }

  const onClickClear = () => {
    onTbvalueChange?.("");
  };

  return (
    <div
      className={`text-box-container searchbox ${
        className ?? ""
      } ${direction} ${justify} ${align}`}
      style={{ flexBasis: "50%" }}
    >
      <div style={{ width: labelWidth }}>
        <span className={["label", labelClassName].join(" ")}>{caption}:</span>
      </div>
      <div style={{ flex: 1 }}>
        <TextBox
          className="text-box"
          value={value}
          readOnly={disabled}
          placeholder={placeholder}
          onValueChanged={tb_onChangedHandler}
          stylingMode={stylingMode}
        />
      </div>
      <button type="button" className="clearButton" onClick={onClickClear}>
        Clear
      </button>
    </div>
  );
}

DefaultSearchBox.propTypes = {
  displayStar: PropTypes.bool,
  caption: PropTypes.string,
  displayValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  stylingMode: PropTypes.oneOf(["outlined", "underlined", "filled"]),
  items: PropTypes.array,
  onValueChanged: PropTypes.func,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  labelWidth: PropTypes.number,
  isResetStyle: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  hideColon: PropTypes.bool,
};
