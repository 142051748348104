import React from "react";

import { Cookies } from "react-cookie";
import WebStudioProvider, {
  useWebStudio,
} from "../app-editor/components/web-studio";
import Widgets from "../app-editor/components/widgets";
import Grid from "../app-editor/components/grid";
import { WIDGETS } from "../app-editor/components/widget.item";
import Card from "../app-editor/components/card";
import { SetCookies, Token } from "../../../utils/default-cookies";
import { GetLoadUdfTable } from "../../../api/user-defined-field";

// import WebStudioProvider, {
//   useWebStudio,
// } from "../../app-editor/components/web-studio";
// import Widgets from "../../app-editor/components/widgets";
// import Grid from "../app-editor/components/grid";
// import { WIDGETS } from "../../app-editor/components/widget.item";
// import Card from "../../../../app-editor/components/card";
// import { GetLoadUdfTable } from "../../../../api/user-defined-field";
// import { SetCookies, Token } from "../../../utils/default-cookies";

const cookies = new Cookies();

function Content({ title }) {
  const { widgets } = useWebStudio();
  return (
    <>
      <Widgets isDetail />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: 24,
        }}
      >
        <h4 style={{ fontSize: 32, fontWeight: 600, marginBottom: 24 }}>
          {title}
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "fit-content",
            padding: 8,
            border: "4px solid #E4F0FD",
          }}
        >
          <Grid>
            {(actions) =>
              widgets.map((widget) => {
                const { Component: Widget } = WIDGETS[widget.type];
                return (
                  Widget && (
                    <Card key={widget.id} actions={actions} {...widget}>
                      <Widget {...widget} />
                    </Card>
                  )
                );
              })
            }
          </Grid>
        </div>
      </div>
    </>
  );
}

export default function UDFDetailView() {
  const [widgets, setWidgets] = React.useState([]);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const fetchApi = async (event) => {
    try {
      const response = await GetLoadUdfTable({ token: Token(), tableId: 10 });
      const layout = response.data.UdfTable.UT_Layout
        ? JSON.parse(response.data.UdfTable.UT_Layout)
        : [];
      setTitle(response.data.UdfTable.UT_Caption);
      setWidgets(layout);
    } catch (error) {
      console.error(error);
    }
  };

  const receiveMessage = (event) => {
    // console.log(event?.data);
    if (event?.data?.accessToken && !Token()) {
      SetCookies(
        cookies,
        event.data.accessToken,
        event.data.userID,
        event.data.companyID,
        event.data.clientID,
        event.data.username,
        event.data.adminYN,
        event.data.administratorYN,
        event.data.language
      );
    }
    if (!initialLoad) {
      fetchApi();
      setInitialLoad(true);
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
      return () => {
        window.removeEventListener("message", receiveMessage, false);
      };
    }
  }, [initialLoad]);

  const onChangeWidget = (widget) => {
    // console.log(widget);
  };

  if (!initialLoad) return null;

  return (
    <div style={{ display: "flex", width: "100vw", height: "100%" }}>
      <WebStudioProvider isEdit widgets={widgets}>
        <Content title={title} />
      </WebStudioProvider>
    </div>
  );
}
