import { useEffect, useRef, useState } from "react";
import { useWebStudio } from "./web-studio";
import Grid from "./grid";
import { WIDGETS } from "./widget.item";
import { Freeze } from "./freeze";
import { PlusCircleIcon } from "../../../../assets";

function OtherGrid({ id, onChange, defaultWidgets = [] }) {
  const [widgets, setWidgets] = useState(defaultWidgets);

  const onChangeGridWidget = async (widgets) => {
    let tempWidgets = [];
    setWidgets((prev) => {
      tempWidgets = widgets(prev);
      onChange?.(tempWidgets);
      return tempWidgets;
    });
  };

  return (
    <Grid setWidgets={onChangeGridWidget} id={id} nested>
      {(actions) =>
        widgets.map((widget) => {
          const { Component: Widget } = WIDGETS[widget.type];
          return (
            Widget && (
              <Card key={widget.id} actions={actions} {...widget}>
                <Widget {...widget} />
              </Card>
            )
          );
        })
      }
    </Grid>
  );
}

function GridTabs({ id, onFocusTab }) {
  const { onChangeProperties, widgets, activeTab, onChangeActiveTab } =
    useWebStudio();
  const currentWidget = widgets.find((widget) => widget.id === id);
  const [changedTab, setChangedTab] = useState();

  const onAppendTab = (newTab) => {
    if (currentWidget) {
      onChangeProperties({
        ...currentWidget,
        tabs: [...currentWidget.tabs, newTab],
      });
    }
  };

  const _onFocusTab = (tabId) => {
    onChangeActiveTab?.(tabId);
    onFocusTab?.(tabId);
  };

  useEffect(() => {
    if (typeof changedTab !== "undefined") {
      const tabs = currentWidget.tabs.map((tab, tabIdx) => {
        if (tabIdx === changedTab.index) {
          return { ...tab, children: changedTab.children };
        }
        return tab;
      });
      onChangeProperties({
        ...currentWidget,
        tabs,
      });
      setChangedTab(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedTab, currentWidget]);

  return (
    <div
      key={id}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          borderColor: "#007BF7",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        {currentWidget?.tabs.map((tab) => (
          <div
            key={tab.id}
            style={{
              width: tab.width,
              borderColor: "#007BF7",
              borderRightWidth: 1,
              borderRightStyle: "solid",
              padding: "12px 24px",
              cursor: "pointer",
              outline: tab.id === activeTab ? "2px solid red" : undefined,
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#007BF7",
              fontSize: 14,
            }}
            onClick={() => {
              _onFocusTab(tab.id);
            }}
          >
            {tab.title}
          </div>
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderColor: "#007BF7",
            borderRightWidth: 1,
            borderRightStyle: "solid",
            padding: "4px 20px",
            cursor: "pointer",
          }}
          onClick={() => {
            const newId = new Date().getTime().toString();
            onAppendTab({
              id: `tab-item-${newId}`,
              title: "Tab Title",
              width: 150,
              children: [],
            });
          }}
        >
          <PlusCircleIcon color="#007BF7" />
        </div>
      </div>
      <div
        style={{ display: "flex", flex: 1, background: "white", padding: 16 }}
      >
        {currentWidget?.tabs.map((tab, index) => (
          <Freeze freeze={activeTab !== tab.id}>
            <OtherGrid
              id={tab.id}
              defaultWidgets={tab.children}
              onChange={(changed) => {
                setChangedTab({ index, children: changed });
              }}
            />
          </Freeze>
        ))}
      </div>
    </div>
  );
}

export default function Card(props) {
  const { id, w, h, x, y, minW, minH, maxW, maxH, actions, children } = props;
  const ref = useRef(null);
  const { setFocusWidget, state } = useWebStudio();

  useEffect(() => {
    actions.handleRemove(ref.current, false);
    actions.handleAdd(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFocus = state?.id === id;
  const isTabs = props.type === "tabs";

  return (
    <div
      ref={ref}
      id={`${id}`} // convert to string
      className={["grid-stack-item", isTabs ? "grid-stack-sub-grid" : undefined]
        .filter(Boolean)
        .join(" ")}
      gs-w={w}
      gs-h={h}
      gs-min-w={minW}
      gs-min-h={minH}
      gs-max-w={maxW}
      gs-max-h={maxH}
      gs-x={x}
      gs-y={y}
      onClick={() => {
        if (!isTabs) setFocusWidget(props, actions.handleRemove, ref.current);
      }}
    >
      <div
        className={`grid-stack-item-content ${
          isFocus ? "grid-stack-item-content-highlight" : ""
        }`}
        {...(isTabs
          ? {
              style: {
                borderRadius: 16,
                overflow: "hidden",
                borderColor: "#007BF7",
                borderWidth: 1,
                borderStyle: "solid",
              },
            }
          : {})}
      >
        {/* {children} */}
        {isTabs ? (
          <GridTabs
            id={id}
            onFocusTab={(currentTab) => {
              setFocusWidget(props, actions.handleRemove, ref.current);
            }}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
}
