import Popup from "devextreme-react/popup";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { Form } from "../app-editor/form";
import Button from "../app-editor/components/button/button";
import DefaultTextBox from "../../../components/text-box/text-box";
import DefaultCheckbox from "../../../components/check-box/check-box";
import { CheckBox } from "devextreme-react";

function Input({ name, label, placeholder, displayStar }) {
  const { field } = useController({ name });
  return (
    <DefaultTextBox
      onTbvalueChange={field.onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={displayStar}
    />
  );
}

function CheckboxField({ name, label }) {
  const { field } = useController({ name });

  return (
    <div style={{ width: "100%" }}>
      <DefaultCheckbox
        onValueChanged={(value) => field.onChange(value)}
        value={field.value}
        name={field.name}
        label={label}
        labelWidth={100}
        flexReverse
      />
    </div>
  );
}

export default function AddNewModulePopup(props) {
  const { onHiding, visible, onSuccess } = props;

  const methods = useForm({
    defaultValues: {
      moduleName: "",
      isActive: false,
    },
  });
  const { reset } = methods;

  const onSubmit = async (values) => {
    try {
      console.log("Success");
      reset();
      onHiding(false);
      onSuccess?.();
    } catch (error) {
      console.error(error);
    }
  };

  const _onHiding = () => {
    reset();
    onHiding(false);
  };

  return (
    <Popup
      // className="item-popup"
      title="Add New Module"
      visible={visible}
      dragEnabled
      showTitle
      width={400}
      height={520}
      closeOnOutsideClick
      onHiding={_onHiding}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <div>
          <Input name="moduleName" label="Module Name" displayStar />
          <CheckboxField name="isActive" label="Active" />
        </div>
        <div style={{ display: "flex", gap: 8, margin: "24px 0 8px" }}>
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={() => {
              onHiding(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Popup>
  );
}
