import React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#757575", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      ref={ref}
      fill={color}
      {...rest}
    >
      <path fill="none" d="M0 0h256v256H0z" />
      <path d="M96.68 57.87a4 4 0 0 1 2.08-6.6A130.13 130.13 0 0 1 128 48c34.88 0 66.57 13.26 91.66 38.35 18.83 18.83 27.3 37.62 27.65 38.41a8 8 0 0 1 0 6.5c-.35.79-8.82 19.57-27.65 38.4q-4.28 4.26-8.79 8.07a4 4 0 0 1-5.55-.36Zm117.24 152.75a8 8 0 1 1-11.84 10.76L180 197.13A127.21 127.21 0 0 1 128 208c-34.88 0-66.57-13.26-91.66-38.34C17.51 150.83 9 132.05 8.69 131.26a8 8 0 0 1 0-6.5c.31-.76 8.82-19.58 27.65-38.41a135 135 0 0 1 25-19.78L42.08 45.38a8 8 0 1 1 11.84-10.76Zm-65.49-48.25-52.69-58a40 40 0 0 0 52.69 58Z" />
    </svg>
  );
};

const EyeSlashIcon = forwardRef(SvgComponent);

export default EyeSlashIcon;
