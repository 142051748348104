import "./styles.scss";
import "gridstack/dist/gridstack.css";
import "gridstack/dist/es5/gridstack-poly.js";
import "gridstack/dist/es5/gridstack-all.js";
import React from "react";
import Widgets from "./components/widgets";
import Grid from "./components/grid";
import Card from "./components/card";
import WebStudioProvider, { useWebStudio } from "./components/web-studio";
import { WIDGETS } from "./components/widget.item";
import { Cookies } from "react-cookie";
import ToolbarUDF from "../../../components/toolbar/toolbar-udf";
import { GetLoadUdfTable } from "../../../api/user-defined-field";
import { SetCookies, Token } from "../../../utils/default-cookies";

const cookies = new Cookies();

export default function AppEditor() {
  const [widgets, setWidgets] = React.useState([]);
  const childToolBarRef = React.useRef();
  const initialLoad = React.useRef(false);
  const [title, setTitle] = React.useState("");

  const fetchApi = async (event) => {
    try {
      const response = await GetLoadUdfTable({ token: Token(), tableId: 10 });
      const layout = response.data.UdfTable.UT_Layout
        ? JSON.parse(response.data.UdfTable.UT_Layout)
        : [];
      setTitle(response.data.UdfTable.UT_Caption);
      setWidgets(layout);
    } catch (error) {
      console.error(error);
    }
  };

  const receiveMessage = (event) => {
    // console.log(event?.data);
    if (event?.data?.accessToken && !Token()) {
      SetCookies(
        cookies,
        event.data.accessToken,
        event.data.userID,
        event.data.companyID,
        event.data.clientID,
        event.data.username,
        event.data.adminYN,
        event.data.administratorYN,
        event.data.language
      );
    }
    if (!initialLoad.current) {
      fetchApi();
      initialLoad.current = true;
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
      return () => {
        window.removeEventListener("message", receiveMessage, false);
      };
    }
  }, []);

  const onChangeWidget = (widget) => {
    // console.log(widget);
  };

  // React.useEffect(() => {
  //   (async () => {
  //     try {
  //       // console.log(widgetValues)
  //       const response = await SaveUdfTable({
  //         token: Token(),
  //         body: {
  //           // ExistingFields: [
  //           //   // {
  //           //   //   UC_ID: 0,
  //           //   //   FieldName: "string",
  //           //   //   FieldType: "string",
  //           //   //   SystemColumn: true,
  //           //   //   LayoutType: "string",
  //           //   //   RfDataTable: "string",
  //           //   //   RfDataToShow: "string",
  //           //   //   DefaultCaption: "string",
  //           //   //   Properties: "string",
  //           //   //   IsDeleted: true,
  //           //   // },
  //           // ],
  //           ExistingFields: widgetValues,
  //           TableId: 5,
  //           TableName: "Customone",
  //           MenuType: 2,
  //           DocNoPrefix: "CTM",
  //           Caption: "CustomingOne",
  //           AppId: 7,
  //           MenuSeq: 0,
  //           Layout: JSON.stringify(widgetValues),
  //           ListLayout: "",
  //         },
  //       });
  //       console.log(response);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, [widgetValues]);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <WebStudioProvider setWidget={onChangeWidget} isEdit>
        <ToolbarUDF
          displayBackBtn={true}
          displaySaveBtn={true}
          displayHideGrid={true}
          displayInfoBtn={false}
          displayListViewBtn={false}
          displayDetailViewBtn={false}
          goBack={() => {}}
        />
        <div style={{ display: "flex", flex: 1 }}>
          <Content title={title} />
        </div>
      </WebStudioProvider>
    </div>
  );
}

function Content({ title }) {
  const { widgets, isHideGrid } = useWebStudio();
  return (
    <>
      <Widgets />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: 24,
        }}
      >
        <h4 style={{ fontSize: 32, fontWeight: 600, marginBottom: 24 }}>
          {title}
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "fit-content",
            padding: 8,
            border: "4px solid #E4F0FD",
          }}
        >
          <Grid isHideGrid={isHideGrid}>
            {(actions) =>
              widgets.map((widget) => {
                const { Component: Widget } = WIDGETS[widget.type];
                return (
                  Widget && (
                    <Card key={widget.id} actions={actions} {...widget}>
                      <Widget {...widget} />
                    </Card>
                  )
                );
              })
            }
          </Grid>
        </div>
      </div>
    </>
  );
}
