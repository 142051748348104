import React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#2977B6", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      ref={ref}
      {...rest}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#a)"
      >
        <path d="M9 12 5 3.5 1 12M12.5 12c1.105 0 2-.784 2-1.75s-.895-1.75-2-1.75-2 .784-2 1.75.895 1.75 2 1.75Z" />
        <path d="M14.5 12V8.25c0-.966-.896-1.75-2-1.75-.598 0-1.134.136-1.5.5M7.825 9.5H2.177" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TextAaIcon = forwardRef(SvgComponent);

export default TextAaIcon;
