import React from "react";
import { Cookies } from "react-cookie";
import TableCard from "../app-editor/components/table-card";
import WebStudioProvider, {
  useWebStudio,
} from "../app-editor/components/web-studio";
import Widgets from "../app-editor/components/widgets";
import DefaultSelectBox from "../../../components/select-box/select-box";
import DefaultSearchBox from "../../../components/text-box/search-box";
import Grid from "../app-editor/components/grid";
import { WIDGETS } from "../app-editor/components/widget.item";
import { GetLoadUdfTable } from "../../../api/user-defined-field";
import { SetCookies, Token } from "../../../utils/default-cookies";
import { PlusCircleFillIcon } from "../../../assets";

const cookies = new Cookies();

const renderHeaderActionCell =
  ({ onClick }) =>
  () => {
    return (
      <button
        type="button"
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#4EB548",
          color: "white",
          padding: "4px 12px",
          gap: 4,
          borderRadius: 4,
        }}
        onClick={onClick}
      >
        <PlusCircleFillIcon />
        <span>New</span>
      </button>
    );
  };

function Content() {
  const { widgets } = useWebStudio();
  return (
    <>
      <Widgets fieldList />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: 24,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 32 }}
        >
          <div style={{ width: "50%" }}>
            <DefaultSelectBox
              direction="row"
              caption="Company"
              labelWidth={100}
            />
          </div>
          <DefaultSearchBox
            direction="row"
            caption="Search"
            align="center"
            labelWidth={100}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {/* <DataGrid dataSource={[]} className={"dx-card wide-card"}>
            <Editing
              mode="cell"
              useIcons={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Column
              allowHiding={false}
              showInColumnChooser={false}
              dataField={"IM_ID"}
              width={90}
              visible={false}
            />
            <Column
              type="buttons"
              fixed
              fixedPosition="left"
              width={90}
              allowResizing={false}
              allowHiding={false}
              showInColumnChooser={false}
              // headerCellRender={renderHeaderActionCell({ onClick: onCreateNewApp })}
              //   cellRender={actionButtonCell(cellAction)}
            />
            <Column
              dataField={"UT_TableName"}
              width={190}
              caption={"Property Name"}
              hidingPriority={8}
            />
            <Column
              dataField={"UT_Caption"}
              caption={"Description"}
              hidingPriority={6}
            />
            <Column
              dataField={"UT_NoOfProperties"}
              caption={"No of Properties"}
              hidingPriority={6}
            />
            <Column
              dataField={"UT_Created"}
              caption={"Created On"}
              hidingPriority={6}
            />
          </DataGrid> */}
          <Grid
            options={{
              column: 12,
              minRow: 1,
              maxRow: 1,
              cellHeight: 40,
              cellWidth: 50,
              disableOneColumnMode: true,
              acceptWidgets: true,
              disableResize: true,
            }}
          >
            {(actions) =>
              widgets.map((widget) => {
                const { Component: Widget } = WIDGETS[widget.type];
                return (
                  Widget && (
                    <TableCard key={widget.id} actions={actions} {...widget}>
                      {widget.label}
                    </TableCard>
                  )
                );
              })
            }
          </Grid>
        </div>
      </div>
    </>
  );
}

export default function UDFListView() {
  const [widgets, setWidgets] = React.useState([]);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const fetchApi = async (event) => {
    try {
      const response = await GetLoadUdfTable({ token: Token(), tableId: 10 });
      const layout = response.data.UdfTable.UT_Layout
        ? JSON.parse(response.data.UdfTable.UT_Layout)
        : [];
      setTitle(response.data.UdfTable.UT_Caption);
      setWidgets(layout);
    } catch (error) {
      console.error(error);
    }
  };

  const receiveMessage = (event) => {
    // console.log(event?.data);
    if (event?.data?.accessToken && !Token()) {
      SetCookies(
        cookies,
        event.data.accessToken,
        event.data.userID,
        event.data.companyID,
        event.data.clientID,
        event.data.username,
        event.data.adminYN,
        event.data.administratorYN,
        event.data.language
      );
    }
    if (!initialLoad) {
      fetchApi();
      setInitialLoad(true);
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
      return () => {
        window.removeEventListener("message", receiveMessage, false);
      };
    }
  }, [initialLoad]);

  const onChangeWidget = (widget) => {
    // console.log(widget);
  };

  if (!initialLoad) return null;

  return (
    <div style={{ display: "flex", width: "100vw", height: "100%" }}>
      <WebStudioProvider isEdit widgets={[]}>
        <Content />
      </WebStudioProvider>
    </div>
  );
}
