import React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#000000", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      ref={ref}
      {...rest}
    >
      <g fill={color} clipPath="url(#a)">
        <path d="M5.75 4.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.25 4.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM5.75 8.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.25 8.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM5.75 13a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.25 13a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DotSixVerticalIcon = forwardRef(SvgComponent);

export default DotSixVerticalIcon;
