import React from "react";
import TextBox from "devextreme-react/text-box";
import PropTypes from "prop-types";
import "./text-box.scss";
import "./text-box-additional.scss";

export default function DefaultTextBox(props) {
  const {
    onTbvalueChange,
    value,
    displayValue,
    disabled,
    caption,
    stylingMode = "outlined",
    placeholder,
    displayStar,
    direction = "vertical",
    labelWidth,
    justify: _justify = "left",
    align: _align = "left",
    isResetStyle,
    className,
    labelClassName,
    hideColon,
  } = props;

  const justify = React.useMemo(() => `justify ${_justify}`, [_justify]);
  const align = React.useMemo(() => `align ${_align}`, [_align]);

  const resetStyle = React.useMemo(
    () => (isResetStyle ? "reset " : ""),
    [isResetStyle]
  );

  function tb_onChangedHandler(event) {
    onTbvalueChange(event.value);
  }

  return (
    <div
      className={`text-box-container ${
        className ?? ""
      } ${direction} ${justify} ${align}`}
    >
      <div
        className={`${resetStyle}dx-fieldset-text`}
        style={{ width: labelWidth }}
      >
        <div className="dx-field">
          <div className="dx-field-label">
            <span className={["label", labelClassName].join(" ")}>
              {caption}
            </span>
            <span>
              {displayStar ? <span style={{ color: "red" }}> * </span> : ""}
              {!hideColon && ":"}
            </span>
            {displayValue === "" ? (
              <span className="required-field">Required</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`${resetStyle}dx-fieldset field`}>
        <div className="dx-field">
          <div className="dx-field-value">
            <TextBox
              className="text-box"
              value={value}
              readOnly={disabled}
              placeholder={placeholder}
              onValueChanged={tb_onChangedHandler}
              stylingMode={stylingMode}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultTextBox.propTypes = {
  displayStar: PropTypes.bool,
  caption: PropTypes.string,
  displayValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  stylingMode: PropTypes.oneOf(["outlined", "underlined", "filled"]),
  items: PropTypes.array,
  onValueChanged: PropTypes.func,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  labelWidth: PropTypes.number,
  isResetStyle: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  hideColon: PropTypes.bool,
};
