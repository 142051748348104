import NumberBox from 'devextreme-react/number-box';
import React from 'react';
import PropTypes from 'prop-types';
import './number-box.scss';

export default function DefaultNumberBox(props) {
    const {
        displayStar,
        caption,
        displayValue,
        value,
        disabled,
        placeholder,
        onValueChanged,
        stylingMode = "outlined",
        format,
        min,
        max,
        direction = "vertical",
        labelWidth,
        isResetStyle,
        justify: _justify = "left",
        align: _align = "left",
        className,
        labelClassName,
        hideColon,
        ...rest
    } = props;

    const justify = React.useMemo(() => `justify ${_justify}`, [_justify]);
    const align = React.useMemo(() => `align ${_align}`, [_align]);
    const resetStyle = React.useMemo(() => isResetStyle ? 'reset ' : '', [isResetStyle]);

    const nb_onChangeHandler = (event) => {
        onValueChanged(event.value)
    }

    return (
        <div className={`number-box-container ${className ?? ''} ${direction} ${justify} ${align}`}>
            <div className={`${resetStyle}dx-fieldset-text`} style={{ width: labelWidth }}>
                <div className="dx-field">
                    <div className="dx-field-label">
                        <span className={["label", labelClassName].join(" ")}>{caption}</span>
                        <>{displayStar ? (<span style={{ color: 'red' }}> * </span>) : ""}{!hideColon && ":"}</>
                        {displayValue === '' ? <span className="required-field">Required</span> : null}
                    </div>
                </div>
            </div>
            <div className={`${resetStyle}dx-fieldset field`}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <NumberBox
                            {...rest}
                            className="number-box"
                            value={value}
                            readOnly={disabled}
                            placeholder={placeholder}
                            onValueChanged={nb_onChangeHandler}
                            stylingMode={stylingMode}
                            format={format}
                            showSpinButtons={true}
                            min={min}
                            max={max}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

DefaultNumberBox.propTypes = {
    displayStar: PropTypes.bool,
    caption: PropTypes.string,
    displayValue: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    stylingMode: PropTypes.oneOf(["outlined", "underlined", "filled"]),
    onValueChanged: PropTypes.func,
    format: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    labelWidth: PropTypes.number,
    isResetStyle: PropTypes.bool,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    hideColon: PropTypes.bool,
}