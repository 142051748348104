// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --main-color: #2977b6;\n  --main-dark-color: #123958;\n}\n\n._35fj1uBzaIJumGz_MImxNr {\n  flex-basis: 350px;\n  background-color: #2977b6;\n  display: flex;\n  flex-direction: column;\n}\n\n._3oDcbElo0sms23UvTadrc1 {\n  padding: 8px 16px;\n  width: 100%;\n}\n\n.Tj3uLAECvHRGUCMsSLOht {\n  width: 100%;\n}\n\n._3qi2LVrTtLpTHexix0nx6E {\n  padding-top: 16px;\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/app-editor/components/style.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,0BAAA;AACF;;AAEA;EAEE,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,WAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[":root {\r\n  --main-color: #2977b6;\r\n  --main-dark-color: #123958;\r\n}\r\n\r\n.tabContainer {\r\n  //   width: 256px;\r\n  flex-basis: 350px;\r\n  background-color: #2977b6;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.buttonContainer {\r\n  padding: 8px 16px;\r\n  width: 100%;\r\n}\r\n\r\n.button {\r\n  width: 100%;\r\n}\r\n\r\n.propertyContainer {\r\n  padding-top: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "_35fj1uBzaIJumGz_MImxNr",
	"buttonContainer": "_3oDcbElo0sms23UvTadrc1",
	"button": "Tj3uLAECvHRGUCMsSLOht",
	"propertyContainer": "_3qi2LVrTtLpTHexix0nx6E"
};
export default ___CSS_LOADER_EXPORT___;
