import React from "react";
import DefaultSearchBox from "../../../components/text-box/search-box";
import { DataGrid } from "devextreme-react";
import { Column, Editing } from "devextreme-react/data-grid";
import { PlusCircleFillIcon } from "../../../assets";
import CreateNewAppPopup from "../create-new-app.popup";
import AddNewModulePopup from "./add-new-module.popup";

const renderHeaderActionCell =
  ({ onClick }) =>
  () => {
    return (
      <button
        type="button"
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#4EB548",
          color: "white",
          padding: "4px 12px",
          gap: 4,
          borderRadius: 4,
        }}
        onClick={onClick}
      >
        <PlusCircleFillIcon />
        <span>New</span>
      </button>
    );
  };

export default function UDFModuleEditor() {
  const [visiblePopup, setVisiblePopup] = React.useState(false);
  const onSuccess = () => {};

  const onCreateNewApp = () => {
    setVisiblePopup(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
      <AddNewModulePopup
        onHiding={setVisiblePopup}
        visible={visiblePopup}
        onSuccess={onSuccess}
      />
      <DefaultSearchBox
        direction="row"
        align="center"
        caption="Search"
        labelWidth={100}
      />
      <h2 style={{ fontWeight: "bold", fontSize: 32 }}>Module Editor</h2>
      <DataGrid dataSource={[]} className={"dx-card wide-card"}>
        <Editing
          mode="cell"
          useIcons={true}
          allowDeleting={true}
          allowUpdating={true}
        />
        <Column
          allowHiding={false}
          showInColumnChooser={false}
          dataField={"IM_ID"}
          width={90}
          visible={false}
        />
        <Column
          type="buttons"
          fixed
          fixedPosition="left"
          width={90}
          allowResizing={false}
          allowHiding={false}
          showInColumnChooser={false}
          headerCellRender={renderHeaderActionCell({ onClick: onCreateNewApp })}
          // cellRender={actionButtonCell(cellAction)}
        />
        <Column
          dataField={"UT_ModuleName"}
          width={190}
          caption={"Module Name"}
          hidingPriority={8}
        />
        <Column dataField={"UT_Type"} caption={"Type"} hidingPriority={6} />
        <Column
          dataField={"UT_CreatedOn"}
          caption={"Created On"}
          hidingPriority={6}
        />
        <Column dataField={"UT_Active"} caption={"Active"} hidingPriority={6} />
        <Column
          dataField={"UT_Sequence"}
          caption={"Sequence"}
          hidingPriority={6}
        />
      </DataGrid>
    </div>
  );
}
