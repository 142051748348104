// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --blue: #007bf7;\n  --red: #f54343;\n  --white: #ffffff;\n}\n\n._2_CoZFxlAm92FpTIFQwEq9 {\n  padding: 8px 12px;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 125px;\n}\n\n._3sY64m27eY-55N3aRJlmfE {\n  background-color: var(--blue);\n  color: var(--white);\n}\n\n._3XG2jOIP0pcDyDzDNsa-iv {\n  background-color: transparent;\n  border: 2px solid var(--white);\n  color: var(--white);\n}\n\n._1YRiy3SCZGtWQgN5UwgNJq {\n  background-color: transparent;\n  border: 2px solid var(--blue);\n  color: var(--blue);\n}\n\n._Dn2RpSfxIm9Rfxy92r0i {\n  color: var(--white);\n  background-color: var(--red);\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/app-editor/components/button/style.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,6BAAA;EACA,mBAAA;AACF;;AAEA;EACE,6BAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,6BAAA;EACA,6BAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,4BAAA;AACF","sourcesContent":[":root {\r\n  --blue: #007bf7;\r\n  --red: #f54343;\r\n  --white: #ffffff;\r\n}\r\n\r\n.container {\r\n  padding: 8px 12px;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-width: 125px;\r\n}\r\n\r\n.primary {\r\n  background-color: var(--blue);\r\n  color: var(--white);\r\n}\r\n\r\n.secondary {\r\n  background-color: transparent;\r\n  border: 2px solid var(--white);\r\n  color: var(--white);\r\n}\r\n\r\n.secondaryBlue {\r\n  background-color: transparent;\r\n  border: 2px solid var(--blue);\r\n  color: var(--blue);\r\n}\r\n\r\n.solidRed {\r\n  color: var(--white);\r\n  background-color: var(--red);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2_CoZFxlAm92FpTIFQwEq9",
	"primary": "_3sY64m27eY-55N3aRJlmfE",
	"secondary": "_3XG2jOIP0pcDyDzDNsa-iv",
	"secondaryBlue": "_1YRiy3SCZGtWQgN5UwgNJq",
	"solidRed": "_Dn2RpSfxIm9Rfxy92r0i"
};
export default ___CSS_LOADER_EXPORT___;
