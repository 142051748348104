import React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#757575", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      ref={ref}
      fill={color}
      {...rest}
    >
      <path fill="none" d="M0 0h256v256H0z" />
      <path d="M208 40H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h72v16H96a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16h-24v-16h72a24 24 0 0 0 24-24V64a24 24 0 0 0-24-24Zm0 144H48a8 8 0 0 1-8-8v-16h176v16a8 8 0 0 1-8 8Z" />
    </svg>
  );
};

const DesktopIcon = forwardRef(SvgComponent);

export default DesktopIcon;
