// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --main-color: #2977b6;\n  --main-dark-color: #123958;\n}\n\n._2xSVR4BQO7fdU-VbxIjXqT {\n  display: flex;\n  width: 100%;\n}\n\n._1aOiaLOklmrSzLTyr5JgjH {\n  background-color: var(--main-color);\n  display: flex;\n  gap: 8px;\n  padding: 12px 0;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  color: white;\n}\n\n._1KQs7P2uGNDk4z_WqJRF53, ._1aOiaLOklmrSzLTyr5JgjH:disabled {\n  background-color: var(--main-dark-color);\n  color: #C6D1DB;\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/app-editor/components/tab/style.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;AACF;;AAEA;EACE,mCAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;EACA,OAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,wCAAA;EACA,cAAA;AACF","sourcesContent":[":root {\r\n  --main-color: #2977b6;\r\n  --main-dark-color: #123958;\r\n}\r\n\r\n.container {\r\n  display: flex;\r\n  width: 100%;\r\n}\r\n\r\n.button {\r\n  background-color: var(--main-color);\r\n  display: flex;\r\n  gap: 8px;\r\n  padding: 12px 0;\r\n  flex: 1;\r\n  justify-content: center;\r\n  align-items: center;\r\n  color: white;\r\n}\r\n\r\n.buttonInactive, .button:disabled {\r\n  background-color: var(--main-dark-color);\r\n  color: #C6D1DB;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2xSVR4BQO7fdU-VbxIjXqT",
	"button": "_1aOiaLOklmrSzLTyr5JgjH",
	"buttonInactive": "_1KQs7P2uGNDk4z_WqJRF53"
};
export default ___CSS_LOADER_EXPORT___;
