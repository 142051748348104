import React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 24, height = 24, color = "#FFFFFF", ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      ref={ref}
      {...rest}
    >
      <g stroke={color} strokeWidth={1.5} clipPath="url(#a)">
        <path
          strokeMiterlimit={10}
          d="M12.5 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.75 12h7.5M12.5 8.25v7.5"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color} d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const PlusCircleIcon = forwardRef(SvgComponent);

export default PlusCircleIcon;
