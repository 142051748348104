import { createContext, useContext, useMemo, useRef, useState } from "react";

const WebStudioContext = createContext({
  setFocusWidget() {},
  onChangeProperties() {},
  onChangeWidgets() {},
  state: null,
  isEdit: false,
  setIsEdit() {},
  activeTab: undefined,
  widgets: [],
  onChangeActiveTab() {},
  isHideGrid: false,
  onHideGrid() {},
});

const { Provider } = WebStudioContext;

export default function WebStudioProvider(props) {
  const { children, setWidget, isEdit } = props;
  const [_state, setState] = useState(null);
  const [isHideGrid, setHideGrid] = useState(false);
  const [_activeTab, setActiveTab] = useState();
  const removeWidgetActionRef = useRef();
  const elRef = useRef();
  const state = useMemo(() => _state, [_state]);
  const [_isEdit, setIsEdit] = useState(isEdit);
  const [widgets, setWidgets] = useState(props.widgets || []);

  const setFocusWidget = (widget, removeWidgetAction, ref, currentTab) => {
    setState(widget);
    removeWidgetActionRef.current = removeWidgetAction;
    elRef.current = ref;
    // setActiveTab(currentTab);
  };

  const onChangeProperties = (widget) => {
    setState(widget);
    setWidget?.(widget);
    setWidgets((prev) => {
      return prev.map((prevItem) => {
        if (prevItem.id !== widget.id) return prevItem;
        return widget;
      });
    });
  };

  const onRemoveWidget = (id) => {
    if (id) {
      setState(undefined);
      removeWidgetActionRef.current?.(elRef.current);
    }
  };

  const onChangeWidgets = (_widgets) => {
    setWidgets(_widgets);
  };

  const onChangeActiveTab = (activeTab) => setActiveTab(activeTab);

  return (
    <Provider
      value={{
        state,
        setFocusWidget,
        onChangeProperties,
        onRemoveWidget,
        isEdit: _isEdit,
        setIsEdit,
        activeTab: _activeTab,
        widgets,
        onChangeWidgets,
        onChangeActiveTab,
        isHideGrid,
        onHideGrid: setHideGrid,
      }}
    >
      {children}
    </Provider>
  );
}

export function useWebStudio() {
  return useContext(WebStudioContext);
}
