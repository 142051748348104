import React from "react";
import styles from "./style.module.scss";

export default function Tabs(props) {
  const { items = [], onChangeTab, currentTab } = props;

  const _onChangeTab = (item) => {
    if (currentTab.key !== item.key) {
      onChangeTab(item);
    }
  };

  return (
    <div className={styles.container}>
      {items.map((item) => (
        <button
          key={item.key}
          type="button"
          className={`${styles.button} ${
            currentTab?.key !== item.key ? styles.buttonInactive : ""
          }`}
          disabled={item.disabled}
          onClick={() => _onChangeTab(item)}
        >
          {item.icon}
          <span>{item.text}</span>
        </button>
      ))}
    </div>
  );
}
