import React, { useState } from "react";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import {
  GetListAllUdfApps,
  GetListAllUdfTables,
} from "../../api/user-defined-field";
import { SetCookies, Token } from "../../utils/default-cookies";
import { Cookies } from "react-cookie";
import CreateNewAppPopup from "./create-new-app.popup";
import { actionButtonCell } from "./custom-cell/edit";
import DefaultSearchBox from "../../components/text-box/search-box";
import styles from "./styles.module.scss";

const cookies = new Cookies();

export default function UserDefinedField() {
  const [data, setData] = React.useState([]);
  const initialLoad = React.useRef(false);
  const [visiblePopup, setVisiblePopup] = useState(false);

  const fetchApi = async () => {
    try {
      const response = await GetListAllUdfTables({ token: Token() });
      console.log(response);
      setData(response.data ?? []);
    } catch (error) {
      console.log(error);
    }
  };

  const receiveMessage = (event) => {
    if (event?.data?.accessToken && !Token()) {
      SetCookies(
        cookies,
        event.data.accessToken,
        event.data.userID,
        event.data.companyID,
        event.data.clientID,
        event.data.username,
        event.data.adminYN,
        event.data.administratorYN,
        event.data.language
      );
    }
    if (!initialLoad.current) {
      fetchApi();
      initialLoad.current = true;
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
      return () => {
        window.removeEventListener("message", receiveMessage, false);
      };
    }
  }, []);

  const getPostMessageObj = (url) => ({
    source: window.location.host,
    target: process.env.REACT_APP_API_ALAYA_URL,
    eval:
      "parent.closeTab('UDF Detail');parent.addTab('UDF Detail', '" +
      url +
      "')",
  });

  const onOpenAppEditorTab = (data) => {
    if (typeof window !== "undefined") {
      window.parent.postMessage(getPostMessageObj(data.EditBtnUrl), "*");
    }
  };

  const onOpenScreenConfigurationTab = (data) => {
    if (typeof window !== "undefined") {
      window.parent.postMessage(
        getPostMessageObj(data.ScreenConfigurationBtnUrl),
        "*"
      );
    }
  };

  const onCreateNewApp = () => {
    setVisiblePopup(true);
  };

  const onSuccess = () => fetchApi();

  const cellAction = {
    edit: {
      onClick: onOpenAppEditorTab,
      enabled: true,
    },
    configuration: {
      onClick: onOpenScreenConfigurationTab,
      enabled: true,
    },
  };

  return (
    <div>
      <CreateNewAppPopup
        onHiding={setVisiblePopup}
        visible={visiblePopup}
        onSuccess={onSuccess}
      />
      <div className={styles.mb32}>
        <DefaultSearchBox
          direction={"row"}
          caption="Search"
          labelWidth={100}
          align="center"
        />
      </div>
      <DataGrid dataSource={data} className={"dx-card wide-card"}>
        <Editing
          mode="cell"
          useIcons={true}
          allowDeleting={true}
          allowUpdating={true}
        />
        <Column
          allowHiding={false}
          showInColumnChooser={false}
          dataField={"IM_ID"}
          width={90}
          visible={false}
        />
        <Column
          type="buttons"
          fixed
          fixedPosition="left"
          width={90}
          allowResizing={false}
          allowHiding={false}
          showInColumnChooser={false}
          // headerCellRender={renderHeaderActionCell({ onClick: onCreateNewApp })}
          cellRender={actionButtonCell(cellAction)}
        />
        <Column
          dataField={"UT_TableName"}
          width={190}
          caption={"Code"}
          hidingPriority={8}
        />
        <Column
          dataField={"UT_Caption"}
          caption={"Description"}
          hidingPriority={6}
        />
      </DataGrid>
    </div>
  );
}
