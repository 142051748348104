import React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 24, height = 24, color = "#FFFFFF", ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      ref={ref}
      {...rest}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
      >
        <path d="M12.5 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" />
        <path d="M12.694 19.323h-.375L9.316 21a9.807 9.807 0 0 1-3.198-1.8l-.011-3.375a4.982 4.982 0 0 1-.188-.32L2.93 13.804a9.296 9.296 0 0 1 0-3.606l2.985-1.697c.061-.108.124-.214.188-.32l.015-3.374A9.804 9.804 0 0 1 9.315 3l3 1.677h.375l3-1.677a9.807 9.807 0 0 1 3.198 1.8l.01 3.375c.067.105.13.21.188.32l2.986 1.7a9.297 9.297 0 0 1 0 3.606l-2.985 1.697c-.06.108-.123.215-.187.32l-.015 3.374A9.804 9.804 0 0 1 15.69 21l-2.995-1.677Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const GearIcon = forwardRef(SvgComponent);
export default GearIcon;
