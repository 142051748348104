const RequestOptions = (token, method, body) => ({
  method,
  headers: new Headers({
    'accept': 'application/json',
    'Content-Type': 'application/json',//application/json
    'Authorization': "Bearer " + token
  }),
  body: body ? JSON.stringify(body) : undefined
})

function handleErrors2(response) {
  if (!response.ok) throw new Error("Session Expired! Please login again.");
  return response;
}

export const GetListAllUdfApps = ({ token, UICulture = 'en-US', Tz }) => {
  return fetch(`${process.env.REACT_APP_API_GET_LIST_ALL_UDF_APPS}?UI-Culture=${UICulture}`,
    RequestOptions(token, "GET"))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: data.Obj.UdfApps
    }));
  // Response Result
  //   "Obj": {
  //   "UdfApps": [
  //     {
  //       "UdfTables": [
  //         {
  //           "MenuType": "string",
  //           "EditBtnUrl": "string",
  //           "UT_ID": 0,
  //           "UT_TableName": "string",
  //           "UT_Caption": "string",
  //           "UT_SystemTable_YN": true,
  //           "UT_MenuType": 0,
  //           "UT_MenuSeq": 0,
  //           "UT_UdfAppId": 0,
  //           "UT_Layout": "string",
  //           "UT_ListLayout": "string",
  //           "UT_DocNoPrefix": "string",
  //           "UT_ListAccessRight": "string",
  //           "UT_FormAccessRight": "string",
  //           "UT_ReportAccessRight": "string",
  //           "UT_DateCreated": "2024-09-29T05:53:39.726Z",
  //           "UT_CreatedBy": 0,
  //           "UT_DateModified": "2024-09-29T05:53:39.726Z",
  //           "UT_ModifiedBy": 0
  //         }
  //       ],
  //       "UA_ID": 0, // AppId
  //       "UA_Name": "string",
  //       "UA_Caption": "string",
  //       "UA_RibbonSeq": 0,
  //       "UA_Active_YN": true,
  //       "UA_DateCreated": "2024-09-29T05:53:39.726Z",
  //       "UA_CreatedBy": 0,
  //       "UA_DateModified": "2024-09-29T05:53:39.726Z",
  //       "UA_ModifiedBy": 0
  //     }
  //   ]
  // }
}

export const GetLoadUdfApp = ({ token, AppId, UICulture = 'en-US', Tz }) => {
  return fetch(`${process.env.REACT_APP_API_GET_LOAD_UDF_APP}?AppId=${AppId}&UI-Culture=${UICulture}`,
    RequestOptions(token, "GET"))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: data.Obj.UdfApp
    }));
  // {
  //   "Result": true,
  //   "Message": "string",
  //   "Obj": {
  //     "UdfApp": {
  //       "UA_ID": 0,
  //       "UA_Name": "string",
  //       "UA_Caption": "string",
  //       "UA_RibbonSeq": 0,
  //       "UA_Active_YN": true,
  //       "UA_DateCreated": "2024-09-29T06:01:30.214Z",
  //       "UA_CreatedBy": 0,
  //       "UA_DateModified": "2024-09-29T06:01:30.214Z",
  //       "UA_ModifiedBy": 0
  //     }
  //   }
  // }
}

export const SaveUdfApp = ({ token, AppId, UICulture = 'en-US', Tz, body }) => {
  return fetch(`${process.env.REACT_APP_API_POST_SAVE_UDF_APP}?AppId=${AppId}&UI-Culture=${UICulture}`,
    RequestOptions(token, "POST", body))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: data.Obj,
      message: data.Message,
      result: data.Result,
    }));
  // Request Body
  // {
  //   "Name": "string",
  //   "Caption": "string",
  //   "RibbonSeq": 0,
  //   "Active_YN": true,
  //   "AppId": 0,
  //   "TableIdSeq": {
  //     "additionalProp1": 0,
  //     "additionalProp2": 0,
  //     "additionalProp3": 0
  //   }
  // }

  // Response Result
  // {
  //   "Result": true,
  //   "Message": "string",
  //   "Obj": {
  //     "Saved": true,
  //     "AppId": 0,
  //     "TableIdSeqSaved": true
  //   }
  // }
}


export const GetListAllUdfTables = ({ token, searchText, UICulture = 'en-US', Tz }) => {
  return fetch(`${process.env.REACT_APP_API_GET_LIST_ALL_UDF_TABLES}?searchText=${searchText}&UI-Culture=${UICulture}`,
    RequestOptions(token, "GET"))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: data.Obj.UdfTables
    }));
  // {
  //   "Result": true,
  //   "Message": "string",
  //   "Obj": {
  //     "UdfTables": [
  //       {
  //         "MenuType": "string",
  //         "EditBtnUrl": "string",
  //         "UT_ID": 0,
  //         "UT_TableName": "string",
  //         "UT_Caption": "string",
  //         "UT_SystemTable_YN": true,
  //         "UT_MenuType": 0,
  //         "UT_MenuSeq": 0,
  //         "UT_UdfAppId": 0,
  //         "UT_Layout": "string",
  //         "UT_ListLayout": "string",
  //         "UT_DocNoPrefix": "string",
  //         "UT_ListAccessRight": "string",
  //         "UT_FormAccessRight": "string",
  //         "UT_ReportAccessRight": "string",
  //         "UT_DateCreated": "2024-09-29T06:06:20.600Z",
  //         "UT_CreatedBy": 0,
  //         "UT_DateModified": "2024-09-29T06:06:20.600Z",
  //         "UT_ModifiedBy": 0
  //       }
  //     ]
  //   }
  // }
}

export const GetLoadUdfTable = ({ token, tableId, UICulture = 'en-US', Tz }) => {
  return fetch(`${process.env.REACT_APP_API_GET_LOAD_UDF_TABLE}?tableId=${tableId}&UI-Culture=${UICulture}`,
    RequestOptions(token, "GET"))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: {
        UdfTable: data.Obj.UdfTable,
        ExistingFields: data.Obj.ExistingFields,
      }
    }));
  // {
  //   "Result": true,
  //   "Message": "string",
  //   "Obj": {
  //     "UdfTable": {
  //       "UT_ID": 0,
  //       "UT_TableName": "string",
  //       "UT_Caption": "string",
  //       "UT_SystemTable_YN": true,
  //       "UT_MenuType": 0,
  //       "UT_MenuSeq": 0,
  //       "UT_UdfAppId": 0,
  //       "UT_Layout": "string",
  //       "UT_ListLayout": "string",
  //       "UT_DocNoPrefix": "string",
  //       "UT_ListAccessRight": "string",
  //       "UT_FormAccessRight": "string",
  //       "UT_ReportAccessRight": "string",
  //       "UT_DateCreated": "2024-09-29T06:09:09.438Z",
  //       "UT_CreatedBy": 0,
  //       "UT_DateModified": "2024-09-29T06:09:09.438Z",
  //       "UT_ModifiedBy": 0
  //     },
  //     "ExistingFields": [
  //       {
  //         "UC_ID": 0,
  //         "FieldName": "string",
  //         "FieldType": "string",
  //         "SystemColumn": true,
  //         "LayoutType": "string",
  //         "RfDataTable": "string",
  //         "RfDataToShow": "string",
  //         "DefaultCaption": "string",
  //         "Properties": "string",
  //         "IsDeleted": true
  //       }
  //     ]
  //   }
  // }
}

export const SaveUdfTable = ({ token, UICulture = 'en-US', Tz, body }) => {
  return fetch(`${process.env.REACT_APP_API_POST_SAVE_UDF_TABLE}?UI-Culture=${UICulture}`,
    RequestOptions(token, "POST", body))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: {
        Saved: data.Obj.Saved,
        TableId: data.Obj.TableId,
        SyncedToDb: data.Obj.SyncedToDb,
        SyncedToDbErrorMsg: data.Obj.SyncedToDbErrorMsg,
      },
      result: data.Result,
      message: data.Message,
    }));
  // Requet Body
  // {
  //   "ExistingFields": [
  //     {
  //       "UC_ID": 0,
  //       "FieldName": "string",
  //       "FieldType": "string",
  //       "SystemColumn": true,
  //       "LayoutType": "string",
  //       "RfDataTable": "string",
  //       "RfDataToShow": "string",
  //       "DefaultCaption": "string",
  //       "Properties": "string",
  //       "IsDeleted": true
  //     }
  //   ],
  //   "TableId": 0,
  //   "TableName": "string",
  //   "MenuType": 0,
  //   "DocNoPrefix": "string",
  //   "Caption": "string",
  //   "AppId": 0,
  //   "MenuSeq": 0,
  //   "Layout": "string",
  //   "ListLayout": "string"
  // }

  //Response Body
  // {
  //   "Result": true,
  //   "Message": "string",
  //   "Obj": {
  //     "Saved": true,
  //     "TableId": 0,
  //     "SyncedToDb": true,
  //     "SyncedToDbErrorMsg": "string"
  //   }
  // }
}

export const SyncUdfToDbSchema = ({ token, UICulture = 'en-US', Tz, body }) => {
  return fetch(`${process.env.REACT_APP_API_PUT_SYNC_UDF_TO_DB_SCHEMA}?UI-Culture=${UICulture}`,
    RequestOptions(token, "POST", body))
    .then(handleErrors2)
    .then(response => response.json())
    .then(data => ({
      data: {
        SyncedToDb: data.Obj.SyncedToDb,
        SyncedToDbErrorMsg: data.Obj.SyncedToDbErrorMsg,
      }
    }));

  //Response Body
  // {
  //   "Result": true,
  //   "Message": "string",
  //   "Obj": {
  //     "SyncedToDb": true,
  //     "SyncedToDbErrorMsg": "string"
  //   }
  // }
}