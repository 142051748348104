import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import styles from "./style.module.scss";

const Button = forwardRef((props, ref) => {
  const {
    children,
    className,
    type = "button",
    variant = "primary",
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      type={type}
      className={`${styles.container} ${styles[variant]} ${className ?? ''}`}
      {...rest}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

Button.displayName = "Button";

export default Button;
