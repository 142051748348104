import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  TasksPage,
  ProfilePage,
  PackageItemPage,
  PackageItemDetails,
  ItemsPage,
  CostMapping,
  PurchaseInvoice,
  DocumentAction,
  AuditLog,
  AccessDenied,
  AppEditor,
  SimpleDemo,
  UserDefinedField,
  UDFDetailView,
  UDFListView,
  UDFModuleEditor,
} from './pages';

const routes = [
  {
    path: '/items',
    component: ItemsPage
  },
  {
    path: '/access-denied',
    component: AccessDenied
  },
  {
    path: '/package-item',
    component: PackageItemPage
  },
  {
    path: '/package-item-details',
    component: PackageItemDetails
  },
  {
    path: '/cost-mapping',
    component: CostMapping
  },
  {
    path: '/purchase-invoice',
    component: PurchaseInvoice
  },
  {
    path: '/document-action',
    component: DocumentAction
  },
  {
    path: '/audit-log',
    component: AuditLog
  },
  {
    path: '/tasks',
    component: TasksPage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/user-defined-field/app-editor',
    component: AppEditor,
  },
  {
    path: '/user-defined-field',
    component: UserDefinedField,
  },
  {
    path: '/user-defined-field/detail',
    component: UDFDetailView,
  },
  {
    path: '/user-defined-field/list',
    component: UDFListView,
  },
  {
    path: '/user-defined-field/module-editor',
    component: UDFModuleEditor,
  },
  {
    path: '/simple-demo',
    component: SimpleDemo,
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
